@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  font-family: 'Raleway', sans-serif;
  /* top: 0px !important; */
}

.text-shadow {
  text-shadow: 6px 6px 4px rgba(0, 0, 0, 0.5);
}

/* scroll style changes */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: theme('colors.primaryColor');
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: theme('colors.primaryColorLight');
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

/* yellow to orange and red */

.leanerGrade-yellow {
  background: linear-gradient(90deg, #ffd700 0%, #ffa500 50%, #ff6347 100%);
}
.card-price {
  display: inline-block;
  /* width: 100px; */
  min-width: 100px;
  height: 38px;
  background-color: theme('colors.primaryColor');
  -webkit-border-radius: 0px 4px 4px 0px;
  -moz-border-radius: 0px 4px 4px 0px;
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid theme('colors.primaryColor');
  position: relative;
  line-height: 38px;
  padding: 0 20px 0 30px;
}

/* Makes the triangle */
.card-price:before {
  content: '';
  position: absolute;
  display: block;
  transform: rotate(180deg);
  left: -1px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid #ffffff;
}

/* /card-price hover */
.card-price:hover {
  border-left: 1px solid theme('colors.secondaryColor');
  background-color: theme('colors.secondaryColor');
}
