/* navbar.css */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.mobile-menu {
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

.slide-in-right {
  animation-name: slideInRight;
}

.slide-out-right {
  animation-name: slideOutRight;
}

.dropdown-content-1 {
  display: none;
  position: absolute;
  background-color: theme('colors.primaryColorLight');
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-1:hover .dropdown-content-1 {
  display: block;
}

.dropdown-content-2 {
  display: none;
  position: absolute;
  background-color: #640a20b9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0;
  right: -90%;
}

.dropdown-2:hover .dropdown-content-2 {
  display: block;
}